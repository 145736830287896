import { FilterOption, SearchFilter } from '../types';

export const updateIndividualSearchFilter = (
  searchFilters: SearchFilter[],
  slug: string,
  attributes: Record<string, FilterOption[] | boolean>,
) => {
  return searchFilters.map((filter) => {
    if (filter.value === slug) {
      return { ...filter, ...attributes };
    }

    return filter;
  });
};

export const toggleFilterOptionSelection = (
  options: FilterOption[],
  value: string,
) => {
  return options.map((option: FilterOption) => {
    if (option.value === value) {
      return { ...option, selected: !option.selected };
    }

    return option;
  });
};

export const clearFilterOptionsSelection = (searchFilters: SearchFilter[]) => {
  return searchFilters.map((filter) => {
    return {
      ...filter,
      options: filter.options?.map((option) => ({
        ...option,
        selected: false,
      })),
    };
  });
};

export const constructSelectedFiltersQueryParams = (
  searchFilters: SearchFilter[],
) => {
  const selectedFilters: Record<string, string> = {};

  searchFilters.forEach((filter) => {
    const selectedOptions = filter.options?.filter((option) => option.selected);
    if (selectedOptions && selectedOptions.length) {
      selectedFilters[filter.value] = selectedOptions
        .map((option) => option.value)
        .join(',');
    }
  });

  return selectedFilters;
};
