const MAX_ITEMS_PER_ROW = 20;
export const SIZES = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
});

export const ASPECT_RATIO_1_1 = 'aspect_ratio_1_1';
export const ASPECT_RATIO_2_3 = 'aspect_ratio_2_3';
export const ASPECT_RATIO_16_6 = 'aspect_ratio_16_6';
export const ASPECT_RATIO_16_9 = 'aspect_ratio_16_9';
export const ASPECT_RATIO_16_14 = 'aspect_ratio_16_14';
export const CHECKOUT = 'checkout';
export const PACKAGES = 'packages';
export const PRODUCT = 'product';
export const PURCHASE = 'purchase';
export const SUBSCRIPTION = 'subscription';
export const SUBSCRIPTION_PURCHASE_ROUTE = '/checkout/subscribe';
export const SUBSCRIPTION_SIGNUP_ROUTE = '/checkout/subscribe/signup';
export const VIDEOS = 'videos';

export { MAX_ITEMS_PER_ROW as default };
