export default class BrowseCardItem {
  constructor(data, playState) {
    this.data = data;
    this.playState = playState;
  }

  live_status() {
    if (this.data.entity_type === 'live_event') {
      return this.data.entity.latest_video?.live_status;
    }

    return this.data.entity.live_status;
  }

  getData() {
    return {
      additional_images: {
        aspect_ratio_1_1: this.data.entity.thumbnails['1_1'],
        aspect_ratio_2_3: this.data.entity.thumbnails['2_3'],
        aspect_ratio_16_6: this.data.entity.thumbnails['16_6'],
        aspect_ratio_16_14: this.data.entity.thumbnails['16_14'],
      },
      description: this.data.entity.description,
      duration: this.data.entity.duration
        ? {
            seconds: this.data.entity.duration.seconds,
            formatted: new Date(this.data.entity.duration.seconds * 1000)
              .toISOString()
              .substr(11, 8),
          }
        : {},
      _embedded: {
        play_state: this.playState,
      },
      has_free_videos: this.data.entity.has_free_videos || false,
      id: this.data.entity_id,
      is_free: this.data.entity.is_free || false,
      items_count: this.data.entity.items_count || 0,
      live_video: this.data.entity.live_video || false,
      live_status: this.live_status(),
      _links: {
        [`${this.data.entity_type}_page`]: { href: this.data.entity.page_url },
      },
      metadata: this.data.entity.metadata || {},
      product_ids: this.data.entity.product_ids || [],
      name: this.data.entity.title,
      seasons_count: this.data.entity.seasons_count,
      thumbnail: this.data.entity.thumbnails['16_9'],
      type: this.data.entity.type || this.data.entity_type,
      videos_count: this.data.entity.videos_count,
    };
  }
}
