import React from 'react';

import { Button } from '@vimeo/iris/components';
import { Text } from '@vimeo/iris/typography';
import classnames from 'classnames';
import { defineMessages } from 'react-intl';

import { formatMessage } from '~/helpers/intl';

import styles from './TypeFilters.module.scss';
import { TypeFilterCounts } from '../Search';
import { ALL_FILTER } from '../services/search';

interface TypeFiltersProps {
  typeFilter: string;
  handleTypeFilterChange: (searchTerm: string) => void;
  typeFilterCounts: TypeFilterCounts;
  theme: string;
}

const TypeFitlers = ({
  typeFilter,
  handleTypeFilterChange,
  typeFilterCounts,
  theme,
}: TypeFiltersProps) => {
  const selectedTypeFilterClass = (filter: string) => {
    return typeFilter === filter ? styles.selected : '';
  };

  const typeFilterButton = (filter: string) => {
    const filterNameKey = filter as keyof typeof typeFilterCounts;
    const count = typeFilterCounts[filterNameKey];

    if (!count) {
      return null;
    }

    const messages = defineMessages({
      video: {
        id: 'search.type_filters.video',
        defaultMessage: 'Videos ({typeFilterCount})',
      },
      product: {
        id: 'search.type_filters.product',
        defaultMessage: 'Products ({typeFilterCount})',
      },
      collection: {
        id: 'search.type_filters.collection',
        defaultMessage: 'Collections ({typeFilterCount})',
      },
      live_event: {
        id: 'search.type_filters.live_event',
        defaultMessage: 'Live Events ({typeFilterCount})',
      },
      all: {
        id: 'search.type_filters.all',
        defaultMessage: 'All ({typeFilterCount})',
      },
    });

    return (
      <Button
        variant='minimalTransparent'
        format='secondary'
        onClick={() => handleTypeFilterChange(filter)}
        data-filter-type={filter}
        className={classnames(
          styles.typeFilter,
          selectedTypeFilterClass(filter),
          {
            [styles.light]: theme === 'light',
          },
        )}
      >
        <Text size={300}>
          {formatMessage(messages[filterNameKey], { typeFilterCount: count })}
        </Text>
      </Button>
    );
  };

  return (
    <div className={styles.typeFiltersContainer}>
      {typeFilterButton(ALL_FILTER)}
      {typeFilterButton('video')}
      {typeFilterButton('live_event')}
      {typeFilterButton('collection')}
      {typeFilterButton('product')}
    </div>
  );
};

export default TypeFitlers;
