import { API_V2_INTERNAL_HEADERS, parseResponse } from '~/utils/api';

import {
  FilterOptionsApiResponse,
  SearchApiResponse,
  SearchFiltersApiResponse,
} from '../../types';

const ALL_FILTER_TYPES = 'video,collection,live_event,product';
export const ALL_FILTER = 'all';

export const getSearchResults = (
  searchTerm: string,
  siteId: string,
  apiV2Url: string,
  filters: Record<string, string> = {},
  page = 1,
  typeFilter: string = ALL_FILTER_TYPES,
): Promise<SearchApiResponse> => {
  typeFilter = typeFilter === ALL_FILTER ? ALL_FILTER_TYPES : typeFilter;

  const params = new URLSearchParams();
  params.set('q', searchTerm);
  params.set('type', typeFilter);
  params.set('page', page.toString());

  let url = `${apiV2BaseUrl(siteId, apiV2Url)}/search?${params.toString()}`;

  const filtersString = filtersObjectToURLParams(filters).toString();
  if (filtersString.length) {
    url += `&${filtersString}`;
  }

  return fetch(url, {
    method: 'GET',
    headers: API_V2_INTERNAL_HEADERS,
  }).then(parseResponse);
};

export const getSearchFilters = (
  siteId: string,
  apiV2Url: string,
): Promise<SearchFiltersApiResponse> => {
  const url = `${apiV2BaseUrl(siteId, apiV2Url)}/search_filters`;

  return fetch(url, {
    method: 'GET',
    headers: API_V2_INTERNAL_HEADERS,
  }).then(parseResponse);
};

export const getFilterOptions = async (
  siteId: string,
  apiV2Url: string,
  slug: string,
  page = 1,
): Promise<FilterOptionsApiResponse> => {
  const params = new URLSearchParams();
  params.set('page', page.toString());

  const url = `${apiV2BaseUrl(
    siteId,
    apiV2Url,
  )}/search_filters/${slug}/options?${params.toString()}`;

  return fetch(url, {
    method: 'GET',
    headers: API_V2_INTERNAL_HEADERS,
  }).then(parseResponse);
};

export const searchEndpointWithParams = (searchTerm: string): string => {
  return `/search?q=${searchTerm}`;
};

const apiV2BaseUrl = (siteId: string, apiUrl: string) => {
  const v2Path = `/v2/sites/${siteId}`;
  return `${apiUrl}${v2Path}`;
};

const filtersObjectToURLParams = (filters: Record<string, string>) => {
  const parsedFilters = new URLSearchParams();
  for (const label in filters) {
    parsedFilters.set(`filters[${label}]`, filters[label]);
  }
  return parsedFilters;
};
