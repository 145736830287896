import React from 'react';

import PropTypes from 'prop-types';

import { truncateText } from 'js/utils/text-helpers';

const descriptionLength = 250;

const tooltipMarkup = (description) => {
  return { __html: description };
};

const Tooltip = (props) => {
  const calculatePosition = {
    opacity: 1,
    position: 'absolute',
    display: 'block',
    left: `${props.offsetLeft ? 'unset' : '105%'}`,
    right: `${props.offsetLeft ? '105%' : 'unset'}`,
    top: 0,
    width: '120%',
    maxHeight: '231px',
    zIndex: 20,
  };

  const hideTooltip = {
    position: 'absolute',
    left: '-99999px',
    width: 'auto',
  };

  const descriptionWithoutNewlines = props.description?.replace(
    /(\r?\n)+/g,
    ' ',
  );
  const description = truncateText(
    descriptionWithoutNewlines,
    0,
    descriptionLength,
  );
  const backgroundStyle = props.darkBackground
    ? ' background-gray-deep '
    : ' background-white ';

  if (props.customStyles) {
    Object.assign(calculatePosition, props.customStyles);
  }

  return (
    <div
      className={`tooltip ${backgroundStyle} ${
        props.offsetLeft ? 'fader-left left' : 'fader-right right'
      }`}
      style={props.hover ? calculatePosition : hideTooltip}
      data-testid='browse-tooltip'
    >
      {props.name ? (
        <h3 className='tooltip-item-title site-font-primary-family'>
          <strong>{props.name}</strong>
        </h3>
      ) : null}
      {props.item_count ? (
        <h4 className='transparent padding-bottom-small site-font-primary-family'>
          <span className='media-count'>{props.item_count}</span>
        </h4>
      ) : null}
      <div
        className='transparent tooltip-description site-font-primary-family'
        style={props.descriptionStyles}
        dangerouslySetInnerHTML={tooltipMarkup(description)}
      />
    </div>
  );
};

Tooltip.propTypes = {
  item_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  customStyles: PropTypes.shape({}),
  darkBackground: PropTypes.bool,
  description: PropTypes.string,
  descriptionStyles: PropTypes.shape({}),
  hover: PropTypes.bool,
  offsetLeft: PropTypes.bool,
};

Tooltip.defaultProps = {
  item_count: '',
  name: '',
  customStyles: {},
  darkBackground: false,
  description: '',
  descriptionStyles: {},
  hover: false,
  offsetLeft: false,
};

export default Tooltip;
