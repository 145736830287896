import React, { useEffect, useState } from 'react';

import { Button, LoaderCircular } from '@vimeo/iris/components';
import { Text } from '@vimeo/iris/typography';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import BrowseCardItem from 'js/browse/browse-react/_shared/browse_card_item';
import BrowseCard from 'js/browse/browse-react/subcomponents/BrowseCard';

import BlankSearchResults from './BlankSearchResults';
import styles from './SearchResults.module.scss';
import TypeFilters from './TypeFilters';
import { MOBILE_MAX_WIDTH } from '../constants';
import { TypeFilterCounts } from '../Search';
import { SearchResult } from '../types';

type SearchResultsProps = {
  searchResults: SearchResult[];
  userSubscribed: boolean;
  apiV1Url: string;
  purchasedProductIds: number[];
  searchTerm: string;
  isLoading: boolean;
  handleLoadMore: () => void;
  isLoadingMore: boolean;
  typeFilter: string;
  handleTypeFilterChange: (filter: string) => void;
  typeFilterCounts: TypeFilterCounts;
  theme: string;
  withFilters: boolean;
};

const SearchResults = ({
  searchResults,
  userSubscribed,
  apiV1Url,
  purchasedProductIds,
  searchTerm,
  isLoading = false,
  handleLoadMore,
  isLoadingMore = false,
  typeFilter,
  handleTypeFilterChange,
  typeFilterCounts,
  theme,
  withFilters,
}: SearchResultsProps) => {
  const [showToolTips, setShowToolTips] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= MOBILE_MAX_WIDTH) {
        setShowToolTips(true);
      } else {
        setShowToolTips(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const trackItemSelected = () => {
    window.Stats.trackEvent(window.Tracking.EVENTS.EVENT_SEARCH_ITEM_SELECTED);
  };

  const resultCards = searchResults.map((result, index) => {
    const browseCardItem = new BrowseCardItem(result, null);
    const transformedSearchResult = browseCardItem.getData();

    return (
      <li
        key={`${result.entity_type}-${result.entity_id}`}
        data-index={index}
        data-item-type={result.entity_type}
        className={classnames(
          styles.searchResult,
          `search-result-${result.entity_type}`,
          {
            [styles.withFilters]: withFilters,
            [styles.light]: theme === 'light',
          },
        )}
      >
        <BrowseCard
          data={transformedSearchResult}
          slug={result.entity.slug}
          userSubscribed={userSubscribed}
          size={showToolTips ? 'medium' : 'small'}
          thumbnailType='aspect_ratio_16_9'
          apiUrl={apiV1Url}
          data-testid='browse-card'
          purchasedProductIds={purchasedProductIds}
          onClick={trackItemSelected}
        />
      </li>
    );
  });

  const searchResultsContainer = () => {
    if (isLoading) {
      return (
        <div>
          <TypeFilters
            typeFilter={typeFilter}
            handleTypeFilterChange={handleTypeFilterChange}
            typeFilterCounts={typeFilterCounts}
            theme={theme}
          />
          <LoaderCircular
            className={styles.loader}
            size='xl'
            data-testid='spinner'
          />
        </div>
      );
    }

    if (!searchResults.length) {
      return <BlankSearchResults searchTerm={searchTerm} theme={theme} />;
    } else {
      const filterNameKey = typeFilter as keyof typeof typeFilterCounts;
      const currentFilterCount = typeFilterCounts[filterNameKey] || 0;

      return (
        <div>
          <TypeFilters
            typeFilter={typeFilter}
            handleTypeFilterChange={handleTypeFilterChange}
            typeFilterCounts={typeFilterCounts}
            theme={theme}
          />
          <ul className={styles.searchResults}>{resultCards}</ul>
          {resultCards.length < currentFilterCount && (
            <Button
              variant='outline'
              format='secondary'
              onClick={() => handleLoadMore()}
              className={classnames(styles.loadMore, {
                [styles.light]: theme === 'light',
              })}
              loading={isLoadingMore}
            >
              <Text size={300}>
                <FormattedMessage
                  id='search.load_more'
                  defaultMessage='Load More'
                />
              </Text>
            </Button>
          )}
        </div>
      );
    }
  };

  return (
    <div
      className={classnames(styles.searchResultsContainer, {
        [styles.withFilters]: withFilters,
      })}
    >
      {searchResultsContainer()}
    </div>
  );
};

export default SearchResults;
