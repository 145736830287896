import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { contains, path } from 'ramda';

import BrowseImage from './BrowseImage';
import ListToggle from './ListToggle';
import Subtext from './Subtext';
import Tooltip from './Tooltip';
import {
  ASPECT_RATIO_16_9,
  ASPECT_RATIO_1_1,
  ASPECT_RATIO_2_3,
  SIZES,
} from '../constants';

const MY_LIST = 'my-list';

class BrowseCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      offsetLeft: false,
      watchlistItem: this.isWatchlistItem(),
      textHeight: null,
    };

    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  setHref() {
    if (this.props.data._links.collection_page) {
      return this.props.data._links.collection_page.href;
    }

    if (this.props.data._links.video_page) {
      return this.props.data._links.video_page.href;
    }

    if (this.props.data._links?.product_page) {
      return this.props.data._links.product_page.href;
    }

    if (this.props.data._links?.live_event_page) {
      return this.props.data._links.live_event_page.href;
    }

    return '';
  }

  setThumbnail() {
    const { thumbnailType } = this.props;
    if ([ASPECT_RATIO_1_1, ASPECT_RATIO_2_3].includes(thumbnailType)) {
      return this.props.data.additional_images[thumbnailType].medium;
    } else if (this.props.data.thumbnail) {
      return this.props.data.thumbnail.medium;
    }
    return '';
  }

  partOfCachedWatchList() {
    const watchlistIDs = JSON.parse(localStorage.getItem('watchlistItems'));
    return (
      watchlistIDs &&
      watchlistIDs[this.props.data.id] &&
      watchlistIDs[this.props.data.id] === this.props.data.type
    );
  }

  isWatchlistItem() {
    return this.partOfCachedWatchList() || this.props.slug === MY_LIST;
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.props.onClick(e);
    }
  }

  handleMouseOut() {
    this.setState({ isHovered: false, offsetLeft: false });
  }

  handleMouseOver() {
    let isOffsetApplied = false;
    const element = this.node,
      clientWidth = document.documentElement.clientWidth;

    if (element && clientWidth) {
      // if card's center is right of the window's center, apply left shift to the tooltip
      const rect = element.getBoundingClientRect(),
        cardCenter = rect.left + rect.width / 2,
        clientCenter = clientWidth / 2;

      isOffsetApplied = cardCenter > clientCenter;
    }

    this.setState({ isHovered: true, offsetLeft: isOffsetApplied });
  }

  hasPlayState() {
    if (this.props.data._embedded && this.props.data._embedded.play_state) {
      return this.props.data._embedded.play_state;
    }

    return '';
  }

  isMyListType() {
    const allowedTypes = ['video', 'series', 'movie', 'playlist', 'live_event'];

    if (contains(path(['data', 'type'], this.props), allowedTypes)) {
      return true;
    }
    return false;
  }

  showMyList() {
    if (
      this.isMyListType() &&
      this.props.size !== 'mobile' &&
      this.props.userSubscribed
    ) {
      return true;
    }
    return false;
  }

  createURL() {
    const id = this.props.data.id;

    if (this.props.data.type === 'video') {
      return `${this.props.apiUrl}/me/watchlist?video=${this.props.apiUrl}/videos/${id}`;
    }

    if (this.props.data.type === 'live_event') {
      return `${this.props.apiUrl}/me/watchlist?live_event=${this.props.apiUrl}/live_events/${id}`;
    }

    return `${this.props.apiUrl}/me/watchlist?collection=${this.props.apiUrl}/collections/${id}`;
  }

  render() {
    return (
      <div
        data-testid='browse-card'
        key={this.props.index}
        className='card-enter horizontal-row-item'
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
        ref={(node) => (this.node = node)}
        data-offset-left={this.state.offsetLeft}
        data-is-hovered={this.state.isHovered}
      >
        {this.props.size !== SIZES.SMALL ? (
          <Tooltip
            offsetLeft={this.state.offsetLeft}
            hover={this.state.isHovered}
            description={this.props.data.description}
            name={this.props.data.name}
            count={this.props.data.items_count}
          />
        ) : null}
        <div className={`browse-item-card item-type-${this.props.data.type}`}>
          <div className='site-font-primary-color browse-item-title'>
            {this.showMyList() ? (
              <ListToggle
                added={this.state.watchlistItem}
                windowSize={this.state.windowWidth}
                parentHovered={this.state.isHovered}
                url={this.createURL()}
                mouseEnterCallback={this.handleMouseOut}
                mouseLeaveCallback={this.handleMouseOver}
                id={this.props.data.id}
                type={this.props.data.type}
              />
            ) : null}
            <a
              href={this.setHref()}
              onClick={this.props.onClick}
              onKeyDown={this.handleKeyDown}
            >
              <BrowseImage
                playstate={this.hasPlayState()}
                thumbnail={this.setThumbnail()}
                isFree={this.props.data.is_free}
                isLive={this.props.data.live_video}
                live_status={this.props.data.live_status}
                userSubscribed={this.props.userSubscribed}
                isHovered={this.state.isHovered}
                type={this.props.data.type}
                hasFreeVideos={this.props.data.has_free_videos}
                duration={this.props.data.duration}
                name={this.props.data.name}
                scheduledAt={this.props.data.scheduled_at}
                videoProductIds={this.props.data.product_ids}
                purchasedProductIds={new Set(this.props.purchasedProductIds)}
                thumbnailType={this.props.thumbnailType}
              />
              <Subtext data={this.props.data} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

BrowseCard.propTypes = {
  size: PropTypes.string,
  index: PropTypes.number,
  userSubscribed: PropTypes.bool,
  slug: PropTypes.string,
  purchasedProductIds: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.shape({
    additional_images: PropTypes.shape({
      [ASPECT_RATIO_1_1]: PropTypes.shape({
        medium: PropTypes.string,
        small: PropTypes.string,
        source: PropTypes.string,
      }),
      [ASPECT_RATIO_2_3]: PropTypes.shape({
        medium: PropTypes.string,
        small: PropTypes.string,
        source: PropTypes.string,
      }),
    }),
    product_ids: PropTypes.arrayOf(PropTypes.number),
    duration: PropTypes.shape({}),
    id: PropTypes.number,
    name: PropTypes.string,
    has_free_videos: PropTypes.bool,
    is_free: PropTypes.bool,
    metadata: PropTypes.shape({
      href: PropTypes.string,
    }),
    thumbnail: PropTypes.shape({
      medium: PropTypes.string,
    }),
    items_count: PropTypes.number,
    description: PropTypes.string,
    type: PropTypes.string,
    live_video: PropTypes.bool,
    live_status: PropTypes.string,
    scheduled_at: PropTypes.string,
    _embedded: PropTypes.shape({
      play_state: PropTypes.shape({}),
    }),
    _links: PropTypes.shape({
      collection_page: PropTypes.shape({
        href: PropTypes.string,
      }),
      product_page: PropTypes.shape({
        href: PropTypes.string,
      }),
      video_page: PropTypes.shape({
        href: PropTypes.string,
      }),
      live_event_page: PropTypes.shape({
        href: PropTypes.string,
      }),
      source: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
  }),
  onClick: PropTypes.func,
  thumbnailType: PropTypes.oneOf([
    ASPECT_RATIO_1_1,
    ASPECT_RATIO_2_3,
    ASPECT_RATIO_16_9,
    null,
  ]),
  apiUrl: PropTypes.string,
};

BrowseCard.defaultProps = {
  size: '',
  index: 0,
  userSubscribed: false,
  isHovered: false,
  slug: '',
  data: {
    duration: {},
    id: 0,
    name: '',
    has_free_videos: false,
    is_free: false,
    metadata: {
      href: '',
    },
    thumbnail: {
      medium: '',
    },
    items_count: 0,
    description: '',
    type: '',
    live_video: 0,
    live_status: '',
    scheduled_at: '',
    _embedded: {
      play_state: {},
    },
    _links: {
      collection_page: {
        href: '',
      },
      video_page: {
        href: '',
      },
      source: {
        href: '',
      },
    },
  },
  onClick: () => {},
  thumbnailType: ASPECT_RATIO_16_9,
};

export default BrowseCard;
