import React from 'react';

import ReactDOM from 'react-dom';

import { Search, SearchProps } from '../apps/Search';

const rootElement = document.getElementById('search-react-app');

const searchProps: SearchProps = JSON.parse(
  rootElement?.dataset?.props || '{}',
);

ReactDOM.render(<Search {...searchProps} />, rootElement);
