import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Tooltip from './Tooltip';

class ListToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      isLoading: false,
      added: this.props.added,
    };

    this.added = this.props.added;
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.toggleStyle = this.toggleStyle.bind(this);
    this.hoverUnavailable = this.hoverUnavailable.bind(this);
    this.determineAPICall = this.determineAPICall.bind(this);
    this.removeFromStorage = this.removeFromStorage.bind(this);
    this.addToStorage = this.addToStorage.bind(this);
    this.trackToggleActivity = this.trackToggleActivity.bind(this);
  }

  setDescription() {
    if (this.state.added) {
      return this.props.intl.formatMessage({
        id: 'browse.list_toggle.remove_from_list',
        defaultMessage: 'Remove from my list',
      });
    }

    return this.props.intl.formatMessage({
      id: 'browse.list_toggle.add_to_list',
      defaultMessage: 'Add to my list',
    });
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
    this.props.mouseEnterCallback();
  }

  handleMouseOut() {
    this.setState({ isHovered: false });
    this.props.mouseLeaveCallback();
  }

  determineAPICall(httpMethod, state) {
    return fetch(this.props.url, {
      method: httpMethod,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.TOKEN}`,
      },
      credentials: 'include',
    }).then((res) => {
      if (res.status === 204) {
        this.setState({
          added: state,
          isLoading: false,
        });
      }
    });
  }

  trackToggleActivity(eventName) {
    var trackingEvent = new CustomEvent(eventName, {
      bubbles: true,
      cancelable: true,
      detail: {
        type: this.props.type,
        id: this.props.id,
      },
    });
    document.dispatchEvent(trackingEvent);
  }

  addToStorage() {
    const watchlistIDs = JSON.parse(localStorage.getItem('watchlistItems'));

    if (watchlistIDs) {
      watchlistIDs[this.props.id] = this.props.type;
      localStorage.setItem('watchlistItems', JSON.stringify(watchlistIDs));
    }
    this.trackToggleActivity('my-list-added');
  }

  removeFromStorage() {
    const watchlistIDs = JSON.parse(localStorage.getItem('watchlistItems'));

    if (watchlistIDs) {
      delete watchlistIDs[this.props.id];
      localStorage.setItem('watchlistItems', JSON.stringify(watchlistIDs));
    }
    this.trackToggleActivity('my-list-removed');
  }

  toggleStyle() {
    const style = {
      zIndex: 10,
    };
    if (!this.props.onFeaturedCarousel) {
      style.opacity = this.state.isHovered ? 1 : 0.7;
      style.visibility =
        this.props.parentHovered ||
        this.state.isHovered ||
        this.hoverUnavailable()
          ? 'visible'
          : 'hidden';
    }
    return style;
  }

  handleClick(e) {
    e.stopPropagation();
    this.setState({ isLoading: true });
    this.state.added
      ? this.determineAPICall('DELETE', false)
      : this.determineAPICall('PUT', true);
    this.state.added ? this.removeFromStorage() : this.addToStorage();
  }

  hoverUnavailable() {
    return this.props.windowSize < 799 || this.props.onFeaturedCarousel;
  }

  render() {
    return (
      <div className='inline-block' data-testid='browse-list-toggle'>
        {this.hoverUnavailable() ? null : (
          <Tooltip
            hover={this.state.isHovered}
            descriptionStyles={{ color: 'white' }}
            offsetLeft={true}
            description={this.setDescription()}
            customStyles={{
              left: 'auto',
              right: 55,
              top: 5,
              padding: '6px 15px',
              width: 'auto',
              opacity: 0.8,
            }}
            darkBackground={true}
          />
        )}
        <button
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseOut}
          className={
            (!this.props.onFeaturedCarousel
              ? 'has-background icon-only '
              : 'btn btn-large btn-transparent slide-button slide-button--alt slide-button--my-list ') +
            (this.state.isLoading ? 'loading icon--xsmall ' : '') +
            (this.state.added ? 'icon-watchlist-remove' : 'icon-watchlist-add')
          }
          style={this.toggleStyle()}
          aria-label={this.setDescription()}
        >
          <FormattedMessage
            id='browse.list_toggle.my_list'
            defaultMessage='My list'
          />
        </button>
      </div>
    );
  }
}

ListToggle.propTypes = {
  parentHovered: PropTypes.bool,
  url: PropTypes.string,
  mouseEnterCallback: PropTypes.func,
  mouseLeaveCallback: PropTypes.func,
  windowSize: PropTypes.number,
  added: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  onFeaturedCarousel: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

ListToggle.defaultProps = {
  parentHovered: false,
  url: '',
  mouseLeaveCallback() {},
  mouseEnterCallback() {},
  windowSize: 850,
  added: false,
  id: '0',
  type: '',
  onFeaturedCarousel: false,
};

export default injectIntl(ListToggle);
export { ListToggle as ListToggleView };
