import React from 'react';

import { Search as SearchIcon } from '@vimeo/iris/icons';
import classnames from 'classnames';

import { formatMessage } from '~/helpers/intl';

import styles from './SearchForm.module.scss';
export interface SearchFormProps {
  searchTerm: string;
  updateSearchTerm: (searchTerm: string) => void;
  theme: string;
}

const SearchForm = ({
  searchTerm,
  updateSearchTerm,
  theme,
}: SearchFormProps) => {
  return (
    <div
      className={classnames(styles.searchFormContainer, 'site-border-color')}
    >
      <div
        className={classnames(styles.searchIconContainer, 'site-border-color')}
      >
        <SearchIcon
          className={classnames(styles.searchIcon, {
            [styles.light]: theme === 'light',
          })}
        />
      </div>
      <input
        className={classnames(styles.searchInput, 'custom-css-search-input', {
          [styles.light]: theme === 'light',
          ['site-font-secondary-color']: theme === 'dark',
        })}
        placeholder={formatMessage({
          id: 'search.search_placeholder',
          defaultMessage: 'Search',
        })}
        value={searchTerm}
        onChange={(e) => updateSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchForm;
