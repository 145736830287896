import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { formatLocalizedDate } from 'js/utils/time-helpers';

const seasonText = (data) => {
  return (
    <FormattedMessage
      id='browse.card.season'
      defaultMessage='{count, plural, one {# season} other {# seasons}}'
      values={{ count: data.seasons_count }}
    />
  );
};

const videoText = (data) => {
  return (
    <FormattedMessage
      id='browse.card.video'
      defaultMessage='{count, plural, one {# video} other {# videos}}'
      values={{ count: data.videos_count }}
    />
  );
};

const setSubtext = (data, intl) => {
  if (data.seasons_count) {
    return seasonText(data);
  }

  if (data.type === 'movie' && data.videos_count) {
    return videoText(data);
  }

  if (data.type === 'playlist' && data.videos_count) {
    return videoText(data);
  }

  if (['product', 'subscription', 'transactional'].includes(data.type)) {
    return (
      <FormattedMessage
        id='browse.card.product'
        defaultMessage='{count, plural, one {# item} other {# items}}'
        values={{ count: data.items_count || 0 }}
      />
    );
  }

  if (!data.seasons_count && data.items_count) {
    return (
      <FormattedMessage
        id='browse.card.series'
        defaultMessage='{count, plural, one {# series} other {# series}}'
        values={{ count: data.items_count }}
      />
    );
  }

  if (data.type === 'live_event' && data.live_status === 'pending') {
    if (data.scheduled_at) {
      const formattedScheduledAt = formatLocalizedDate(
        data.scheduled_at,
        intl.locale,
      );
      return (
        <FormattedMessage
          id='browse.features.scheduled_for'
          defaultMessage='Scheduled for {date}'
          values={{ date: formattedScheduledAt }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id='browse.card.live_event.pending'
          defaultMessage='This event has not started yet'
        />
      );
    }
  }

  return null;
};

const Subtext = (props) => {
  const nbsp = '\u00A0';
  return (
    <div className='subtext-container'>
      <div className='site-font-primary-color truncate'>{props.data.name}</div>
      <div
        className='site-font-secondary-color browse-item-subtext'
        data-testid='browse-item-subtext'
      >
        {setSubtext(props.data, props.intl) || nbsp}
      </div>
    </div>
  );
};

Subtext.propTypes = {
  data: PropTypes.shape({
    items_count: PropTypes.number,
    name: PropTypes.string,
    live_status: PropTypes.string,
    scheduled_at: PropTypes.string,
    seasons_count: PropTypes.number,
    type: PropTypes.string,
  }),
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

Subtext.defaultProps = {
  data: {},
};

export default injectIntl(Subtext);
