import React, { useState } from 'react';

import { LoaderCircular, Menu } from '@vimeo/iris/components';
import { Checkmark } from '@vimeo/iris/icons';
import { Link, Text } from '@vimeo/iris/typography';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './Filter.module.scss';
import { toggleFilterOptionSelection } from '../helpers';
import { getFilterOptions } from '../services/search';
import { FilterOption, SearchFilter } from '../types';

type FilterProps = {
  filter: SearchFilter;
  siteId: string;
  apiV2Url: string;
  theme: string;
  handleFilterChange: (
    slug: string,
    attributes: Record<string, FilterOption[] | boolean>,
  ) => void;
  handleApplyFiltersDisabledChange: (state: boolean) => void;
};

const Filter = ({
  filter,
  siteId,
  apiV2Url,
  theme,
  handleFilterChange,
  handleApplyFiltersDisabledChange,
}: FilterProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [allFiltersLoaded, setAllFiltersLoaded] = useState<boolean>(false);

  const selectedFiltersCountLabel = () => {
    const count =
      filter.options?.filter((option) => option.selected).length || 0;

    if (count > 0) {
      return `${filter.label} (${count})`;
    }

    return filter.label;
  };

  const loadFilterOptions = async () => {
    try {
      const response = await getFilterOptions(
        siteId,
        apiV2Url,
        filter.value,
        page,
      );

      let options = filter.options || [];
      const maxResults = response.pagination.count;

      options = options.concat(response.options);

      if (options.length >= maxResults) {
        setAllFiltersLoaded(true);
      }

      handleFilterChange(filter.value, {
        isLoading: false,
        options: options,
      });
    } catch {
      handleFilterChange(filter.value, {
        isLoading: false,
        options: filter.options || [],
      });
    }
  };

  const handleFilterOptionClick = (value: string) => {
    handleFilterChange(filter.value, {
      options: toggleFilterOptionSelection(filter.options || [], value),
    });
    handleApplyFiltersDisabledChange(false);
  };

  const filterSubMenu = () => {
    if (filter.isLoading) {
      return (
        <LoaderCircular
          className={styles.loader}
          size='sm'
          data-testid='spinner'
        />
      );
    } else if (
      !filter.isLoading &&
      (!filter.options || !filter.options.length)
    ) {
      return (
        <Text size={200} className={styles.noFilters}>
          <FormattedMessage
            id='search.filter.no_filters'
            defaultMessage='No filters'
          />
        </Text>
      );
    } else if (filter.options) {
      const filterOptions = filter.options.map((option) => {
        return (
          <Menu.Item
            key={`${filter.value}-${option.value}`}
            className={classnames(styles.subfilter, {
              [styles.light]: theme === 'light',
            })}
            onClick={() => handleFilterOptionClick(option.value)}
          >
            <div className={styles.subfilterContent}>
              <div className={styles.subfilterLabel}>{option.label}</div>
              {option.selected && (
                <Checkmark
                  className={styles.checkmark}
                  data-testid={`${filter.value}_${option.value}_filter_option_checkmark`}
                />
              )}
            </div>
          </Menu.Item>
        );
      });

      return filterOptions;
    }
  };

  const loadMoreLink = () => {
    if (
      filter.isLoading ||
      (filter.options && filter.options?.length === 0) ||
      allFiltersLoaded
    ) {
      return null;
    } else {
      const loadMoreText = (
        <Link
          variant='minimal'
          className={classnames(styles.loadMore, {
            [styles.light]: theme === 'light',
            [styles.disabledLink]: isLoadingMore,
          })}
          onClick={() => loadMoreHandler()}
        >
          <Text size={200}>
            <FormattedMessage
              id='search.filter.load_more'
              defaultMessage='Load more'
            />
          </Text>
        </Link>
      );

      return (
        <div className={styles.loadMoreContainer}>
          {loadMoreText}
          {isLoadingMore && (
            <LoaderCircular
              className={classnames(styles.loader, styles.loadMoreSpinner)}
              size='sm'
              data-testid='spinner'
            />
          )}
        </div>
      );
    }
  };

  const loadMoreHandler = async () => {
    try {
      setIsLoadingMore(true);

      await loadFilterOptions();

      setPage(page + 1);
      setIsLoadingMore(false);
    } catch {
      setIsLoadingMore(false);
    }
  };

  const filterItemClickHandler = async () => {
    if (!filter.options && filter.isLoading) {
      await loadFilterOptions();
      setPage(page + 1);
    }
  };

  return (
    <Menu.Item
      className={classnames(styles.filter, {
        [styles.light]: theme === 'light',
      })}
      id={`${filter.value}-search-filter-menu-item`}
      onOpen={filterItemClickHandler}
      toggle
    >
      {selectedFiltersCountLabel()}
      {filterSubMenu()}
      {loadMoreLink()}
    </Menu.Item>
  );
};

export default Filter;
