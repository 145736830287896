import React from 'react';

import { Header } from '@vimeo/iris/typography';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './BlankSearchResults.module.scss';

type BlankSearchResultsProps = {
  searchTerm: string;
  theme: string;
};

const BlankSearchResults = ({ searchTerm, theme }: BlankSearchResultsProps) => {
  if (searchTerm.length >= 3) {
    return (
      <Header
        size='3'
        className={classnames(styles.noResults, 'site-font-primary-family', {
          [styles.light]: theme === 'light',
        })}
      >
        <FormattedMessage
          id='search.no_results'
          defaultMessage='No results for {searchTerm}'
          values={{ searchTerm }}
        />
      </Header>
    );
  } else {
    return (
      <Header
        size='3'
        className={classnames(styles.noResults, 'site-font-primary-family', {
          [styles.light]: theme === 'light',
        })}
      >
        <FormattedMessage
          id='search.start_typing'
          defaultMessage='Start typing to see results'
        />
      </Header>
    );
  }
};

export default BlankSearchResults;
