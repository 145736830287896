import React, { useEffect, useState } from 'react';

import { Button, Menu } from '@vimeo/iris/components';
import { Filters as FilterIcon } from '@vimeo/iris/icons';
import { Text } from '@vimeo/iris/typography';
import classnames from 'classnames';

import { formatMessage } from '~/helpers/intl';

import Filter from './Filter';
import styles from './Filters.module.scss';
import { MOBILE_MAX_WIDTH } from '../constants';
import {
  constructSelectedFiltersQueryParams,
  updateIndividualSearchFilter,
} from '../helpers';
import { getSearchFilters } from '../services/search';
import { FilterOption, SearchFilter } from '../types';

type FiltersProps = {
  searchFilters: SearchFilter[];
  handleSearchFiltersChange: (filters: SearchFilter[]) => void;
  handleSelectedFiltersUpdate: (filters: Record<string, string>) => void;
  siteId: string;
  apiV2Url: string;
  theme: string;
};

const Filters = ({
  searchFilters,
  handleSearchFiltersChange,
  handleSelectedFiltersUpdate,
  siteId,
  apiV2Url,
  theme,
}: FiltersProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(
    window.innerWidth > MOBILE_MAX_WIDTH,
  );
  const [showFiltersToggle, setShowFiltersToggle] = useState<boolean>(
    window.innerWidth <= MOBILE_MAX_WIDTH,
  );
  const [applyFiltersDisabled, setApplyFiltersDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= MOBILE_MAX_WIDTH) {
        setShowFilters(true);
        setShowFiltersToggle(false);
      } else {
        setShowFilters(false);
        setShowFiltersToggle(true);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getSearchFilters(siteId, apiV2Url).then((response) => {
      const parsedSearchFilters = response.search_filters.map(
        (filter: SearchFilter) => {
          return Object.assign(filter, { isLoading: true, options: null });
        },
      );

      handleSearchFiltersChange(parsedSearchFilters);
    });
  }, [siteId, apiV2Url, handleSearchFiltersChange]);

  const handleFilterChange = (
    slug: string,
    attributes: Record<string, FilterOption[] | boolean>,
  ) => {
    handleSearchFiltersChange(
      updateIndividualSearchFilter(searchFilters, slug, attributes),
    );
  };

  const filterItems = searchFilters.map((filter) => {
    return (
      <Filter
        filter={filter}
        siteId={siteId}
        apiV2Url={apiV2Url}
        theme={theme}
        key={filter.value}
        handleFilterChange={handleFilterChange}
        handleApplyFiltersDisabledChange={setApplyFiltersDisabled}
      />
    );
  });

  const handleApplyFiltersSelect = () => {
    handleSelectedFiltersUpdate(
      constructSelectedFiltersQueryParams(searchFilters),
    );
    setApplyFiltersDisabled(true);
    if (window.innerWidth <= MOBILE_MAX_WIDTH) {
      setShowFilters(false);
    }
  };

  const filtersContainer = () => {
    return (
      <div className={styles.filtersContainer}>
        <div className={styles.filterButtonsContainer}>
          {showFiltersToggle && (
            <Button
              variant='minimalTransparent'
              format='secondary'
              icon={<FilterIcon />}
              className={classnames(styles.filtersToggle, {
                [styles.selected]: showFilters,
                [styles.light]: theme === 'light',
              })}
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              <Text size={300}>
                {formatMessage({
                  id: 'search.filters',
                  defaultMessage: 'Filters',
                })}
              </Text>
            </Button>
          )}
          <Button
            variant='minimalTransparent'
            format='secondary'
            className={classnames(styles.filtersToggle, {
              [styles.light]: theme === 'light',
            })}
            onClick={() => handleApplyFiltersSelect()}
            disabled={applyFiltersDisabled}
          >
            <Text size={300}>
              {formatMessage({
                id: 'search.apply_filters',
                defaultMessage: 'Apply filters',
              })}
            </Text>
          </Button>
        </div>

        <Menu
          className={classnames(styles.filters, {
            [styles.hidden]: !showFilters,
          })}
          data-testid='test-filters-menu-container'
        >
          <Menu.Section>{filterItems}</Menu.Section>
        </Menu>
      </div>
    );
  };

  if (searchFilters.length) {
    return filtersContainer();
  } else {
    return null;
  }
};

export default Filters;
